<template>
    <div>
        <v-container fluid class="mb-8">
            <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Attachment</v-subheader>
            </v-row>
            <v-row align="center">
                <v-col cols="6" v-show="submit_selfie == true">
                    <div class="card-file-upload">
                        <label for="selfie_input" class="file-upload" @drop="drop('selfie')" @dragover="dragover" @dragleave="dragleave">
                            <input id="selfie_input" type="file" multiple hidden ref="selfie_file" accept="image/*"
                                    @change="onChange('selfie')"/>
                            <img :src="selfie_src" alt="" :style="inherit" v-if="selfie_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_selfie" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_selfie" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_selfie, attachmentlabelcolor: error_selfie_color }">Selfie</p>
                            </div>
                            <div class="hover-file" v-if="selfie_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('selfie')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Selfie</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
                <v-col cols="6" v-show="submit_signature == true">
                    <div class="card-file-upload">
                        <label for="signature_input" class="file-upload" @drop="drop('signature')" @dragover="dragover" @dragleave="dragleave">
                            <input id="signature_input" type="file" multiple hidden ref="signature_file" accept="image/*"
                                    @change="onChange('signature')"/>
                            <img :src="signature_src" alt="" :style="inherit" v-if="signature_src"/>
                            <div class="card-text" v-else>
                                <!-- <v-icon class="md-size-2x">camera_alt</v-icon> -->
                                <img v-show="!loading_signature" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_signature" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_signature, attachmentlabelcolor: error_signature_color }">Signature</p>
                            </div>
                            <div class="hover-file" v-if="signature_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('signature')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Signature</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="6" v-show="submit_permanent_appointment == true">
                    <div class="card-file-upload">
                        <label for="permanent_appointment_input" class="file-upload" @drop="drop('permanent_appointment')" @dragover="dragover" @dragleave="dragleave">
                            <input id="permanent_appointment_input" type="file" multiple hidden ref="permanent_appointment_file" accept="image/*"
                                    @change="onChange('permanent_appointment')"/>
                            <img :src="permanent_appointment_src" alt="" :style="inherit" v-if="permanent_appointment_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_permanent_appointment" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_permanent_appointment" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_permanent_appointment, attachmentlabelcolor: error_permanent_appointment_color }">Permanent Appointment</p>
                            </div>
                            <div class="hover-file" v-if="permanent_appointment_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('permanent_appointment')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Permanent Appointment</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
                <v-col cols="6" v-show="submit_school_id == true"> 
                    <div class="card-file-upload">
                        <label for="school_id_input" class="file-upload" @drop="drop('school_id')" @dragover="dragover" @dragleave="dragleave">
                            <input id="school_id_input" type="file" multiple hidden ref="school_id_file" accept="image/*"
                                    @change="onChange('school_id')"/>
                            <img :src="school_id_src" alt="" :style="inherit" v-if="school_id_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_school_id" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_school_id" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_school_id, attachmentlabelcolor: error_school_id_color }">School ID</p>
                            </div>
                            <div class="hover-file" v-if="school_id_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('school_id')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">School ID</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>  
            </v-row>
            <v-row align="center">
                <v-col cols="6" v-show="submit_prc_id == true"> 
                    <div class="card-file-upload">
                        <label for="prc_id_input" class="file-upload" @drop="drop('prc_id')" @dragover="dragover" @dragleave="dragleave">
                            <input id="prc_id_input" type="file" multiple hidden ref="prc_id_file" accept="image/*"
                                    @change="onChange('prc_id')"/>
                            <img :src="prc_id_src" alt="" :style="inherit" v-if="prc_id_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_prc_id" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_prc_id" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_prc_id, attachmentlabelcolor: error_prc_id_color }">PRC ID</p>
                            </div>
                            <div class="hover-file" v-if="prc_id_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('prc_id')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">PRC ID</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
                <v-col cols="6" v-show="submit_payslip == true"> 
                    <div class="card-file-upload">
                        <label for="payslip_input" class="file-upload" @drop="drop('payslip')" @dragover="dragover" @dragleave="dragleave">
                            <input id="payslip_input" type="file" multiple hidden ref="payslip_file" accept="image/*"
                                    @change="onChange('payslip')"/>
                            <img :src="payslip_src" alt="" :style="inherit" v-if="payslip_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_payslip" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_payslip" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_payslip, attachmentlabelcolor: error_payslip_color }">Payslip</p>
                            </div>
                            <div class="hover-file" v-if="payslip_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('payslip')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Payslip</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
            </v-row>
            <v-row align="center" v-show="showAdditionalPayslip1 || submit_additional_payslip_1 == true">
                <v-col cols="6"> 
                    <div class="card-file-upload">
                        <label for="additional_payslip_1_input" class="file-upload" @drop="drop('additional_payslip_1')" @dragover="dragover" @dragleave="dragleave">
                            <input id="additional_payslip_1_input" type="file" multiple hidden ref="additional_payslip_1_file" accept="image/*"
                                    @change="onChange('additional_payslip_1')"/>
                            <img :src="additional_payslip_1_src" alt="" :style="inherit" v-if="additional_payslip_1_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_additional_payslip_1" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_additional_payslip_1" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_additional_payslip_1, attachmentlabelcolor: error_additional_payslip_1_color }">Additional Payslip 1 <br> <span style="color: #4f2684;"><strong>Optional</strong></span></p>
                            </div>
                            <div class="hover-file" v-if="additional_payslip_1_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('additional_payslip_1')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Additional Payslip 1 <br> <strong>Optional</strong></p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>

                <v-col cols="6" v-show="showAdditionalPayslip2 || submit_additional_payslip_2 == true"> 
                    <div class="card-file-upload">
                        <label for="additional_payslip_2_input" class="file-upload" @drop="drop('additional_payslip_2')" @dragover="dragover" @dragleave="dragleave">
                            <input id="additional_payslip_2_input" type="file" multiple hidden ref="additional_payslip_2_file" accept="image/*"
                                    @change="onChange('additional_payslip_2')"/>
                            <img :src="additional_payslip_2_src" alt="" :style="inherit" v-if="additional_payslip_2_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_additional_payslip_2" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_additional_payslip_2" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_additional_payslip_2, attachmentlabelcolor: error_additional_payslip_2_color }">Additional Payslip 2 <br> <span style="color: #4f2684;"><strong>Optional</strong></span></p>
                            </div>
                            <div class="hover-file" v-if="additional_payslip_2_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('additional_payslip_2')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Additional Payslip 2 <br> <strong>Optional</strong></p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
            </v-row>
            <v-row align="center" v-show="showAdditionalPayslip3 || submit_additional_payslip_3 == true">
                <v-col cols="6"> 
                    <div class="card-file-upload">
                        <label for="additional_payslip_3_input" class="file-upload" @drop="drop('additional_payslip_3')" @dragover="dragover" @dragleave="dragleave">
                            <input id="additional_payslip_3_input" type="file" multiple hidden ref="additional_payslip_3_file" accept="image/*"
                                    @change="onChange('additional_payslip_3')"/>
                            <img :src="additional_payslip_3_src" alt="" :style="inherit" v-if="additional_payslip_3_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_additional_payslip_3" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_additional_payslip_3" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_additional_payslip_3, attachmentlabelcolor: error_additional_payslip_3_color }">Additional Payslip 3 <br> <span style="color: #4f2684;"><strong>Optional</strong></span></p>
                            </div>
                            <div class="hover-file" v-if="additional_payslip_3_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('additional_payslip_3')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Additional Payslip 3 <br> <strong>Optional</strong></p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
            </v-row>

            <v-row align="center">
                <v-col>
                    <v-checkbox @change="error_checkbox = false, error_checkbox_color = false" v-model="terms_and_conditions_checkbox" color="csb_yellow">
                        <template v-slot:label>
                            <div :class="{ checkbox_label: error_checkbox, checkbox_color: error_checkbox_color }">
                            I agree to the
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <router-link v-on:click.native="tickCheckbox()" v-on="on" to="/terms-and-conditions">
                                            Private Information Provisions for Loan Application
                                        </router-link>
                                    </template>
                                    Opens in new page
                                </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>


            <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-container>

        <v-row  align="center" justify="space-around" class="bottom-nav">
            <v-col v-show="resubmit == false" class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleSubmit()" depressed class="bottom-nav-btn">
                    <v-progress-circular v-if="submitted"
                    :size="20"
                    indeterminate
                    color="csb_purple"
                ></v-progress-circular>
                <span v-else>SUBMIT</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {
    mdiDelete,
  } from '@mdi/js'

import { submitMariaApplication, submitMariaAttachment, submitCustomerData, submitCustomerAttachment, chatFuel } from '@/api/attachment'
import { mapGetters } from 'vuex'

import imageCompression from 'browser-image-compression';
import BeatLoader from 'vue-spinner/src/PulseLoader.vue'


export default {
    computed: {
        ...mapGetters([
            'calculator',
            'profile',
            'others',
            'resubmit',
            'fb_fname',
            'fb_lname',
            'fb_messengerid',
            'fb_gender',
            'fb_profilepic'
        ]),
    },
    components: {
        BeatLoader
    },            
    data: function() {
        return{
            signature: [],
            permanent_appointment: [],
            prc_id: [],
            payslip: [],
            selfie: [],
            school_id: [],

            showAdditionalPayslip1: false,
            additional_payslip_1: [],

            showAdditionalPayslip2: false,
            additional_payslip_2: [],

            showAdditionalPayslip3: false,
            additional_payslip_3: [],
            
            inherit: 'height:inherit',
            signature_src: null,
            signature_file: {},
            permanent_appointment_src: null,
            permanent_appointment_file: {},
            prc_id_src: null,
            prc_id_file: {},
            school_id_src: null,
            school_id_file: {},
            payslip_src: null,
            payslip_file: {},
            selfie_src: null,
            selfie_file: {},

            additional_payslip_1_src: null,
            additional_payslip_1_file: {},

            additional_payslip_2_src: null,
            additional_payslip_2_file: {},

            additional_payslip_3_src: null,
            additional_payslip_3_file: {},

            submitted: false,

            icons: {
                mdiDelete,
            },

            error_selfie: false,
            error_signature: false,
            error_permanent_appointment: false,
            error_payslip: false,
            error_prc_id: false,
            error_school_id: false,

            error_additional_payslip_1: false,
            error_additional_payslip_2: false,
            error_additional_payslip_3: false,


            error_selfie_color: false,
            error_signature_color: false,
            error_permanent_appointment_color: false,
            error_payslip_color: false,
            error_prc_id_color: false,
            error_school_id_color: false,

            error_additional_payslip_1_color: false,
            error_additional_payslip_2_color: false,
            error_additional_payslip_3_color: false,

            snackbar: false,
            text: 'Only images are accepted as signature.',
            timeout: 2000,

            customer_id: '',

            error_files: [],
            submitted_sucessfully: [],

            submit_signature: true,
            submit_permanent_appointment: true,
            submit_prc_id: true,
            submit_payslip: true,
            submit_selfie: true,
            submit_school_id: true,
            submit_additional_payslip_1: false,
            submit_additional_payslip_2: false,
            submit_additional_payslip_3: false,

            submit_customer_data: {},
            submit_maria_application: {},

            loading_signature: false,
            loading_permanent_appointment: false,
            loading_prc_id: false,
            loading_payslip: false,
            loading_selfie: false,
            loading_school_id: false,
            loading_additional_payslip_1: false,
            loading_additional_payslip_2: false,
            loading_additional_payslip_3: false,

            error_checkbox: false,
            error_checkbox_color :false,
            terms_and_conditions_checkbox: false,

        }
    },
    methods:{
        handleBack(){
            this.$router.push({ path: '/others/index' })
        },
        convertNumber: function(value){
            return  Number(value.replace(/[^0-9.-]+/g,""));
        },
        delay(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        handleTermsAndConditions(){
            this.$router.push({ path: '/terms-and-conditions' })
        },
        // To prevent ticking checkbox upon clicking terms and conditions
        tickCheckbox(){
            this.terms_and_conditions_checkbox = !this.terms_and_conditions_checkbox
        },
        validateCheckbox(){
            if(this.terms_and_conditions_checkbox == true){
                return true
            } else {
                this.error_checkbox = true
                this.error_checkbox_color = true
                setTimeout(() => {
                    this.error_checkbox = false
                }, 500)
                return false
            }
        },
        async handleSubmit(){

            let valid_attachment = this.validateAttachment()
            let valid_checkbox = this.validateCheckbox()
    
            if( valid_attachment && valid_checkbox ){

                var dif = Date.now() - new Date(this.profile.residence_date);
                var ageDate = new Date(dif); // miliseconds from epoch
                var lengthOfStay = Math.abs(ageDate.getUTCFullYear() - 1970);

                var submit_data = { // POST DATA

                    "first_name": this.profile.first_name,
                    "middle_name": this.profile.middle_name,
                    "last_name": this.profile.last_name,
                    "suffix": this.profile.suffix,
                    "dob": this.profile.birthdate,
                    "pob_province": this.profile.birth_place_province,
                    "pob_town": this.profile.birth_place_city,
                    "nationality": 'Philippines',
                    "beneficiery_first_name": this.profile.first_name,
                    "beneficiery_last_name": this.profile.last_name,
                    "beneficiery_middle_name": this.profile.middle_name,
                    "beneficiery_suffix": this.profile.suffix,
                    "gender": this.profile.gender,
                    "civil_status": this.profile.civil_status,
                    "landline_no": null,
                    "mobile_no": this.profile.mobile_no,
                    "email_address": this.profile.email_address,
                    "pres_province": this.profile.province,
                    "pres_city": this.profile.city,
                    "pres_barangay": this.profile.barangay,
                    "pres_street": this.profile.street,
                    "pres_address_details": this.profile.province + " " + this.profile.city + " " + this.profile.barangay + " " + this.profile.street,
                    "pres_type_of_ownership": this.profile.ownership_type,
                    "pres_resident_since": this.profile.residence_date,
                    "pres_length_of_stay": lengthOfStay,
                    "pres_zip": null,
                    "perm_province": this.profile.province,
                    "perm_city": this.profile.city,
                    "perm_barangay": this.profile.barangay,
                    "perm_street": this.profile.street,
                    "perm_address_details": this.profile.province + " " + this.profile.city + " " + this.profile.barangay + " " + this.profile.street,
                    "perm_type_of_ownership": this.profile.ownership_type,
                    "perm_resident_since": this.profile.residence_date,
                    "perm_length_of_stay": lengthOfStay,
                    "perm_zip": null,
                    "id_details": [
                        {
                        "legal_id": this.others.prc_id_no,
                        "name_on_id": this.profile.last_name + ", " + this.profile.first_name,
                        "issue_authority": "ID3",
                        "issue_date": this.others.prc_registration_date,
                        "expiration_date": this.others.valid_until,
                        "document_name": "PRC ID"
                        }
                    ],
                    "source_of_funds": [
                        {
                        "description": "Employed",
                        "sector": "Government",
                        "nature_of_work": "Education",
                        "employer_name": "Baguio - Division Office",
                        "province": "25",
                        "city": "361",
                        "barangay": "41936",
                        "street": "street",
                        "bldg": "building",
                        "contact_person": null,
                        "contact_no": null,
                        "date_hired": this.others.date_hired,
                        "employment_status": this.others.nature_of_appointment,
                        "rank": "Rank",
                        "position": this.others.designation,
                        "monthly_income": this.convertNumber(this.others.monthly_salary),
                        "nthp": this.convertNumber(this.calculator.nthp),
                        "monthly_allowance": 0,
                        "employee_no": this.calculator.employeeNumber,
                        "department": "Department"
                        }
                    ]
                }

                var submit_data_maria = {
                    "first_name": this.profile.first_name,
                    "middle_name": this.profile.middle_name,
                    "last_name": this.profile.last_name,
                    "suffix": this.profile.suffix,
                    "gender": this.profile.gender,
                    "civil_status": this.profile.civil_status,
                    "employee_no": this.calculator.employeeNumber,
                    "region_code": this.calculator.regionInput,
                    "division_code": this.calculator.divisionCode,
                    "station_code": this.calculator.stationCode,
                    "province": this.profile.province,
                    "city": this.profile.city,
                    "barangay": this.profile.barangay,
                    "street_address": this.profile.street,
                    "house_number": this.profile.house_number,
                    "mobile_no": this.profile.mobile_no,
                    "email_address": this.profile.email_address,
                    "dob": this.profile.birthdate,
                    "birthplace_province": this.profile.birth_place_province,
                    "birthplace_city": this.profile.birth_place_city,
                    "resident_since": this.profile.residence_date,
                    "type_of_ownership": this.profile.ownership_type,
                    "prc_id": this.others.prc_id_no,
                    "prc_id_reg_date": this.others.prc_registration_date,
                    "prc_id_exp_date": this.others.valid_until,
                    "date_hired": this.others.date_hired,
                    "designation": this.others.designation,
                    "nature_of_appointment": this.others.nature_of_appointment,
                    "loan_amount": this.calculator.loan_amount,
                    "loan_type": this.others.loan_type,
                    "loan_purpose": this.others.loan_purpose,
                    "monthly_amortization": this.calculator.monthly_amo,
                    "term":  this.calculator.payment_term,
                    "interest": this.calculator.rate,
                    "ref_deped_id": this.others.employee_number,
                    "ref_fname": this.others.first_name,
                    "ref_mname": this.others.middle_name,
                    "ref_lname": this.others.last_name,
                    "ref_suffix": this.others.suffix,
                    "ref_dob": this.others.birthdate,
                    "ref_mobile_no": this.others.mobile_no,
                    "sof":"Employed",
                    "school_sysid": this.calculator.schoolInput,
                    "school_id": this.calculator.schoolInput,
                    "name_of_school": this.calculator.schoolName,
                    "employee_id":"",
                    "work_province":"25",
                    "work_city":"361",
                    "work_barangay":"41936",
                    "work_street":"Pres. Osmena Blvd cor. P. Burgos St.",
                    "monthly_salary": this.convertNumber(this.others.monthly_salary),
                    "nthp": this.convertNumber(this.calculator.nthp),
                    "fb_fname": this.fb_fname, 
                    "fb_lname": this.fb_lname, 
                    "fb_messengerid": this.fb_messengerid, 
                    "fb_gender": this.fb_gender, 
                    "fb_profilepic": this.fb_profilepic,
                    "email_verified":"No",

                    "promo_code":this.calculator.promo_code
                }

                this.$store.dispatch('user/overlay', true)
                this.submitted = true

                if(this.resubmit == false) {
                
                    // submit to 'customer?nospouse=1'
                    this.$store.dispatch('user/overlayText', 'Saving application..')
                    this.submit_customer_data = await this.handleSubmitCustomerData(submit_data)
                    if(this.submit_customer_data.status == "success") {

                        // submit to 'maria/deped',
                        this.$store.dispatch('user/overlayText', 'Saving application.')
                        this.submit_maria_application = await this.handleSubmitMariaApplication(submit_data_maria)
                        if(this.submit_maria_application.status == "success") {

                            this.application_id = this.submit_maria_application.application_id
                        
                            this.submitAttachments()

                        } else {
                            this.$store.dispatch('user/overlayText', 'Maria application submission failed!')
                            await this.delay(2000);
                            this.$store.dispatch('user/overlay', false)
                            this.submitted = false
                        }  

                    } else {
                        this.$store.dispatch('user/overlayText', 'Application submission failed!')
                        await this.delay(2000);
                        this.$store.dispatch('user/overlay', false)
                        this.submitted = false
                    }  
                } else { // if submission fails
                    this.submitAttachments()
                }
            }
        
        },
        async submitAttachments(){
            var upload_files = {}
            var error_count = 0

            var total_attachment = 0

            if(this.resubmit == false){
                total_attachment = 6

                if(this.additional_payslip_1_file instanceof File)
                    ++total_attachment
                if(this.additional_payslip_2_file instanceof File)
                    ++total_attachment
                if(this.additional_payslip_3_file instanceof File)
                    ++total_attachment
            } else {
                total_attachment = this.error_files.length
            }

             // selfie
            if(this.submit_selfie == true) {
                var msg1 = ''
                if(this.resubmit == false) {
                    msg1 = 'Uploading attachment 1/' + total_attachment + ': Selfie'
                } else {
                    var index1 = this.error_files.findIndex(i => i == 'selfie' )
                    msg1 = 'Uploading attachment ' + (index1+1) + '/' + total_attachment + ': Selfie'
                }

                this.$store.dispatch('user/overlayText', msg1) 
                var selfie = this.selfie_file
                var selfie_data_customer = {
                    file: selfie,
                    doc_type: 'Customer Photo',
                    doc_desc: 'file',
                    type: 'Customer Photo',
                    id: this.submit_customer_data.customer_id
                }

                var selfie_data = {
                    file: selfie,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'selfie'
                }

                var customer_attachment_selfie = await this.handleSubmitCustomerAttachment(selfie_data_customer)
                var maria_attachment_selfie = await this.handleSubmitMariaAttachment(selfie_data)

                upload_files.selfie = customer_attachment_selfie && maria_attachment_selfie
            }

            // signature
            if(this.submit_signature == true) {
                var msg2 = ''
                if(this.resubmit == false) {
                    msg2 = 'Uploading attachment 2/' + total_attachment + ': Signature'
                } else {
                    var index2 = this.error_files.findIndex(i => i == 'signature' )
                    msg2 = 'Uploading attachment ' + (index2+1) + '/' + total_attachment + ': Signature'
                }

                this.$store.dispatch('user/overlayText', msg2) 
                var signature = this.signature_file
                var signature_data = {
                    file: signature,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'signature'
                }
                
                upload_files.signature = await this.handleSubmitMariaAttachment(signature_data)
            }

            // Permanent Appointment
            if(this.submit_permanent_appointment == true) {
                var msg3 = ''
                if(this.resubmit == false) {
                    msg3 = 'Uploading attachment 3/' + total_attachment + ': Permanent Appointment'
                } else {
                    var index3 = this.error_files.findIndex(i => i == 'permanent_appointment' )
                    msg3 = 'Uploading attachment ' + (index3+1) + '/' + total_attachment + ': Permanent Appointment'
                }

                this.$store.dispatch('user/overlayText', msg3)
                var permanent_appointment = this.permanent_appointment_file
                var permanent_appointment_data = {
                    file: permanent_appointment,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'permanent_appointment'
                }
                
                upload_files.permanent_appointment = await this.handleSubmitMariaAttachment(permanent_appointment_data)
            }

            //School ID
            if(this.submit_school_id == true) {
                var msg6 = ''
                if(this.resubmit == false) {
                    msg6 = 'Uploading attachment 4/' + total_attachment + ': School ID'
                } else {
                    var index6 = this.error_files.findIndex(i => i == 'school_id' )
                    msg6 = 'Uploading attachment ' + (index6+1) + '/' + total_attachment + ': School ID'
                }

                this.$store.dispatch('user/overlayText', msg6)
                var school_id = this.school_id_file
                var school_id_data = {
                    file: school_id,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'school_id'
                }
                
                upload_files.school_id = await this.handleSubmitMariaAttachment(school_id_data)
            }

            //PRC ID
            if(this.submit_prc_id == true) {
                var msg5 = ''
                if(this.resubmit == false) {
                    msg5 = 'Uploading attachment 5/' + total_attachment + ': PRC ID'
                } else {
                    var index5 = this.error_files.findIndex(i => i == 'prc_id' )
                    msg5 = 'Uploading attachment ' + (index5+1) + '/' + total_attachment + ': PRC ID'
                }

                this.$store.dispatch('user/overlayText', msg5)
                var prc_id = this.prc_id_file
                var prc_id_data_customer = {
                    file: prc_id,
                    doc_type: 'PRC',
                    doc_desc: 'PRC ID',
                    type: 'ID',
                    id: this.submit_customer_data.id_no_list
                }

                var prc_id_data = {
                    file: prc_id,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'prc_id'
                }

                var customer_attachment_prc_id = await this.handleSubmitCustomerAttachment(prc_id_data_customer)
                var maria_attachment_prc_id = await this.handleSubmitMariaAttachment(prc_id_data)

                upload_files.prc_id = customer_attachment_prc_id && maria_attachment_prc_id
            }

            // Payslip
            if(this.submit_payslip == true) {
                var msg4 = ''
                if(this.resubmit == false) {
                    msg4 = 'Uploading attachment 6/' + total_attachment + ': Payslip'
                } else {
                    var index4 = this.error_files.findIndex(i => i == 'payslip' )
                    msg4 = 'Uploading attachment ' + (index4+1) + '/' + total_attachment + ': Payslip'
                }

                this.$store.dispatch('user/overlayText', msg4)
                var payslip = this.payslip_file
                var payslip_data = {
                    file: payslip,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'payslip'
                }
                
                upload_files.payslip = await this.handleSubmitMariaAttachment(payslip_data)
            }

            if(this.additional_payslip_1_file instanceof File) {

                // Additional payslip 1
                var msg7 = ''
                if(this.resubmit == false) {
                    msg7 = 'Uploading attachment 7/' + total_attachment + ': Additional Payslip 1'
                } else {
                    var index7 = this.error_files.findIndex(i => i == 'additional_payslip_1' )
                    msg7 = 'Uploading attachment ' + (index7+1) + '/' + total_attachment + ': Additional Payslip 1'
                }

                this.$store.dispatch('user/overlayText', msg7)
                var additional_payslip_1 = this.additional_payslip_1_file
                var additional_payslip_1_data = {
                    file: additional_payslip_1,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'additional_payslip_1'
                }
                upload_files.additional_payslip_1 = await this.handleSubmitMariaAttachment(additional_payslip_1_data)

            }
                
            if(this.additional_payslip_2_file instanceof File) {

                // Additional payslip 2
                var msg8 = ''
                if(this.resubmit == false) {
                    msg8 = 'Uploading attachment 8/' + total_attachment + ': Additional Payslip 2'
                } else {
                    var index8 = this.error_files.findIndex(i => i == 'additional_payslip_2' )
                    msg8 = 'Uploading attachment ' + (index8+1) + '/' + total_attachment + ': Additional Payslip 2'
                }

                this.$store.dispatch('user/overlayText', msg8)
                var additional_payslip_2 = this.additional_payslip_2_file
                var additional_payslip_2_data = {
                    file: additional_payslip_2,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'additional_payslip_2'
                }
                upload_files.additional_payslip_2 = await this.handleSubmitMariaAttachment(additional_payslip_2_data)
                
            }

            if(this.additional_payslip_3_file instanceof File) {

                // Additional payslip 3
                var msg9 = ''
                if(this.resubmit == false) {
                    msg9 = 'Uploading attachment 9/' + total_attachment + ': Additional Payslip 3'
                } else {
                    var index9 = this.error_files.findIndex(i => i == 'additional_payslip_3' )
                    msg9 = 'Uploading attachment ' + (index9+1) + '/' + total_attachment + ': Additional Payslip 3'
                }

                this.$store.dispatch('user/overlayText', msg9)
                var additional_payslip_3 = this.additional_payslip_3_file
                var additional_payslip_3_data = {
                    file: additional_payslip_3,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'additional_payslip_3'
                }
                upload_files.additional_payslip_3 = await this.handleSubmitMariaAttachment(additional_payslip_3_data)
                
            }

            // clear error_files and submitted_sucessfully
            this.error_files = []
            this.submitted_sucessfully = []

            Object.keys(upload_files).forEach(key => {
                if(!upload_files[key]){
                    this.error_files.push(key)
                    error_count++
                }
            })

            if(error_count==0){
                await this.callChatFuel()
                this.$store.dispatch('user/overlayText', 'Application successfully submitted!') 
                await this.delay(2000);
                this.$store.dispatch('user/overlay', false)
                this.submitted = false
                this.$store.dispatch('user/submitted', true)
                this.$router.replace({ path: '/last-page' })            
                
            }else{
                var file_text = (error_count==1) ? ' file ' : ' files '
                var err_msg = 'Created user but error on uploading files.' + error_count + file_text +'('+ this.error_files.join(', ') +')'
                this.$store.dispatch('user/overlayText', 'Application attachment submission failed - ' + err_msg)
                await this.delay(2000);
                this.$store.dispatch('user/overlay', false)
                this.submitted = false
                
                this.$store.dispatch('user/resubmit', true) // resubmit

                Object.keys(upload_files).forEach(key => {
                    if(upload_files[key]){
                        this.submitted_sucessfully.push(key)
                    }

                    this.deleteAttachment(key)
                })

                this.error_files.forEach(i => {
                    switch(i) {
                        case 'signature':
                            this.submit_signature = true
                            break;
                        case 'permanent_appointment':
                            this.submit_permanent_appointment = true
                            break;
                        case 'prc_id':
                            this.submit_prc_id = true
                            break;
                        case 'payslip':
                            this.submit_payslip = true
                            break;
                        case 'selfie':
                            this.submit_selfie = true
                            break;
                        case 'school_id':
                            this.submit_school_id = true
                            break;
                        case 'additional_payslip_1':
                            this.submit_additional_payslip_1 = true
                            break;
                        case 'additional_payslip_2':
                            this.submit_additional_payslip_2 = true
                            break;
                        case 'additional_payslip_3':
                            this.submit_additional_payslip_3 = true
                            break;
                        default:
                            // default block
                    }
                })

                this.submitted_sucessfully.forEach(i => {
                    switch(i) {
                        case 'signature':
                            this.submit_signature = false
                            break;
                        case 'permanent_appointment':
                            this.submit_permanent_appointment = false
                            break;
                        case 'prc_id':
                            this.submit_prc_id = false
                            break;
                        case 'payslip':
                            this.submit_payslip = false
                            break;
                        case 'selfie':
                            this.submit_selfie = false
                            break;
                        case 'school_id':
                            this.submit_school_id = false
                            break;
                        case 'additional_payslip_1':
                            this.submit_additional_payslip_1 = false
                            break;
                        case 'additional_payslip_2':
                            this.submit_additional_payslip_2 = false
                            break;
                        case 'additional_payslip_3':
                            this.submit_additional_payslip_3 = false
                            break;
                        default:
                            // default block
                    }
                })
            }
        },
        handleSubmitCustomerData: async function(submit_data){
            var application_status = {}
            await submitCustomerData(submit_data).then(async response => {
                application_status.status = "success"
                application_status.customer_id = response.data.data.customer_id
                application_status.id_no_list = response.data.data.id_no_list
            }).catch(error => {
                // this.$log.error(error);
                this.snackbar = true
                this.text = 'error saving ' + error
                application_status.status = "error"
            });

            return application_status
        },
        handleSubmitMariaApplication: async function(submit_data){
            var application_status = {}
            await submitMariaApplication(submit_data).then(async response => {
                application_status.status = "success"
                application_status.sys_id = response.data.data.sys_id
                application_status.application_id = response.data.data.application_id
            }).catch(error => {
                // this.$log.error(error);
                this.snackbar = true
                this.text = 'error saving ' + error
                application_status.status = "error"
            });

            return application_status
        },
        handleSubmitCustomerAttachment: async function(file_data){
            var status = false

            var formData = new FormData();

            formData.append("file", file_data.file);
            formData.append("document_type", file_data.doc_type);
            formData.append("document_description", file_data.doc_desc);
            formData.append("type", file_data.type);
            formData.append("id", file_data.id);

            await submitCustomerAttachment(formData).then(() => {
                status = true
            }).catch(error => {
                // this.$log.error(error);
                this.snackbar = true
                this.text = 'error saving ' + error
                status = false
            });

            return status
        },
        handleSubmitMariaAttachment: async function(file_data){
            var status = false

            var formData = new FormData();

            formData.append("file", file_data.file);
            formData.append("app_sys_id", file_data.sys_id);
            formData.append("description", file_data.description); 

            await submitMariaAttachment(formData).then(() => {
                status = true
            }).catch(error => {
                // this.$log.error(error);
                this.snackbar = true
                this.text = 'error saving ' + error
                status = false
            });

            return status
        },
        callChatFuel: async function(){

            var submit_data={
                "application_id": this.application_id,
                "messenger_id": this.fb_messengerid
            }

            chatFuel(submit_data, {product: 'DEPED'}).catch(error => {
                this.$log.error(error);
            });
        },
        onProgress(attachment){
            this[attachment] = true
        },
        blobToFile(theBlob, fileName){       
            return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
        },
        async onChange(type) {
            event.preventDefault();
            var reader = ''
            var file_type = null
            var input = null
            switch (type) {
                case 'signature':
                    if(this.$refs.signature_file.files.length){
                        file_type = this.$refs.signature_file.files[0].type

                        if(file_type.includes('image')){
                            var signature_file = this.$refs.signature_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_signature')
                            }
                            this.signature_file = this.blobToFile(await imageCompression(signature_file, options), signature_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.signature_src = reader.result
                            };
                            reader.readAsDataURL(this.signature_file);
                            this.error_signature_color = false
                            this.loading_signature = false
                        }else{
                            input = this.$refs.signature_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'permanent_appointment':
                    if(this.$refs.permanent_appointment_file.files.length){
                        file_type = this.$refs.permanent_appointment_file.files[0].type

                        if(file_type.includes('image')){
                            var permanent_appointment_file = this.$refs.permanent_appointment_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_permanent_appointment')
                            }
                            this.permanent_appointment_file = this.blobToFile(await imageCompression(permanent_appointment_file, options), permanent_appointment_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.permanent_appointment_src = reader.result
                            };
                            reader.readAsDataURL(this.permanent_appointment_file);
                            this.error_permanent_appointment_color = false
                            this.loading_permanent_appointment = false
                        }else{
                            input = this.$refs.permanent_appointment_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'prc_id':
                    if(this.$refs.prc_id_file.files.length){
                        file_type = this.$refs.prc_id_file.files[0].type

                        if(file_type.includes('image')){
                            var prc_id_file = this.$refs.prc_id_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_prc_id')
                            }
                            this.prc_id_file = this.blobToFile(await imageCompression(prc_id_file, options), prc_id_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.prc_id_src = reader.result
                            };
                            reader.readAsDataURL(this.prc_id_file);
                            this.error_prc_id_color = false
                            this.loading_prc_id = false
                        }else{
                            input = this.$refs.prc_id_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'school_id':
                    if(this.$refs.school_id_file.files.length){
                        file_type = this.$refs.school_id_file.files[0].type

                        if(file_type.includes('image')){
                            var school_id_file = this.$refs.school_id_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_school_id')
                            }
                            this.school_id_file = this.blobToFile(await imageCompression(school_id_file, options), school_id_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.school_id_src = reader.result
                            };
                            reader.readAsDataURL(this.school_id_file);
                            this.error_school_id_color = false
                            this.loading_school_id = false
                        }else{
                            input = this.$refs.school_id_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'payslip':
                    if(this.$refs.payslip_file.files.length){
                        file_type = this.$refs.payslip_file.files[0].type

                        if(file_type.includes('image')){
                            var payslip_file = this.$refs.payslip_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_payslip')
                            }
                            this.payslip_file = this.blobToFile(await imageCompression(payslip_file, options), payslip_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.payslip_src = reader.result
                            };
                            reader.readAsDataURL(this.payslip_file);
                            this.error_payslip_color = false
                            this.loading_payslip = false

                            // show additional payslip 1
                            this.showAdditionalPayslip1 = true
                        }else{
                            input = this.$refs.payslip_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break; 
                case 'selfie':
                    if(this.$refs.selfie_file.files.length){
                        file_type = this.$refs.selfie_file.files[0].type

                        if(file_type.includes('image')){
                            var selfie_file = this.$refs.selfie_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_selfie')
                            }
                            this.selfie_file = this.blobToFile(await imageCompression(selfie_file, options), selfie_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.selfie_src = reader.result
                            };
                            reader.readAsDataURL(this.selfie_file);
                            this.error_selfie_color = false
                            this.loading_selfie = false
                        }else{
                            input = this.$refs.selfie_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;   
                case 'additional_payslip_1':
                    if(this.$refs.additional_payslip_1_file.files.length){
                        file_type = this.$refs.additional_payslip_1_file.files[0].type

                        if(file_type.includes('image')){
                            var additional_payslip_1_file = this.$refs.additional_payslip_1_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_additional_payslip_1')
                            }
                            this.additional_payslip_1_file = this.blobToFile(await imageCompression(additional_payslip_1_file, options), additional_payslip_1_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.additional_payslip_1_src = reader.result
                            };
                            reader.readAsDataURL(this.additional_payslip_1_file);
                            this.error_additional_payslip_1_color = false

                            // show additional payslip 2
                            this.showAdditionalPayslip2 = true
                            this.loading_additional_payslip_1 = false
                        }else{
                            input = this.$refs.additional_payslip_1_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'additional_payslip_2':
                    if(this.$refs.additional_payslip_2_file.files.length){
                        file_type = this.$refs.additional_payslip_2_file.files[0].type

                        if(file_type.includes('image')){
                            var additional_payslip_2_file = this.$refs.additional_payslip_2_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_additional_payslip_2')
                            }
                            this.additional_payslip_2_file = this.blobToFile(await imageCompression(additional_payslip_2_file, options), additional_payslip_2_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.additional_payslip_2_src = reader.result
                            };
                            reader.readAsDataURL(this.additional_payslip_2_file);
                            this.error_additional_payslip_2_color = false
                            
                            // show additional payslip 3
                            this.showAdditionalPayslip3 = true
                            this.loading_additional_payslip_2 = false
                        }else{
                            input = this.$refs.additional_payslip_2_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'additional_payslip_3':
                    if(this.$refs.additional_payslip_3_file.files.length){
                        file_type = this.$refs.additional_payslip_3_file.files[0].type

                        if(file_type.includes('image')){
                            var additional_payslip_3_file = this.$refs.additional_payslip_3_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_additional_payslip_3')
                            }
                            this.additional_payslip_3_file = this.blobToFile(await imageCompression(additional_payslip_3_file, options), additional_payslip_3_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.additional_payslip_3_src = reader.result
                            };
                            reader.readAsDataURL(this.additional_payslip_3_file);
                            this.error_additional_payslip_3_color = false
                            this.loading_additional_payslip_3 = false
                        }else{
                            input = this.$refs.additional_payslip_3_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        drop(type) {
            event.preventDefault();
            var file_type = null
            file_type = event.dataTransfer.files[0].type
            switch (type) {
                case 'signature':
                    if(file_type.includes('image')){
                        this.$refs.signature_file.files = event.dataTransfer.files;
                        this.onChange('signature');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'permanent_appointment':
                    if(file_type.includes('image')){
                        this.$refs.permanent_appointment_file.files = event.dataTransfer.files;
                        this.onChange('permanent_appointment');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'prc_id':
                    if(file_type.includes('image')){
                        this.$refs.prc_id_file.files = event.dataTransfer.files;
                        this.onChange('prc_id');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'school_id':
                    if(file_type.includes('image')){
                        this.$refs.school_id_file.files = event.dataTransfer.files;
                        this.onChange('school_id');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'payslip':
                    if(file_type.includes('image')){
                        this.$refs.payslip_file.files = event.dataTransfer.files;
                        this.onChange('payslip');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'selfie':
                    if(file_type.includes('image')){
                        this.$refs.selfie_file.files = event.dataTransfer.files;
                        this.onChange('selfie');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'additional_payslip_1':
                    if(file_type.includes('image')){
                        this.$refs.additional_payslip_1_file.files = event.dataTransfer.files;
                        this.onChange('additional_payslip_1');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'additional_payslip_2':
                    if(file_type.includes('image')){
                        this.$refs.additional_payslip_2_file.files = event.dataTransfer.files;
                        this.onChange('additional_payslip_2');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'additional_payslip_3':
                    if(file_type.includes('image')){
                        this.$refs.additional_payslip_3_file.files = event.dataTransfer.files;
                        this.onChange('additional_payslip_3');
                    }else{
                        this.snackbar = true
                    }
                    break;
                default:
                    break;
            }
        },
        dragover() {
            event.preventDefault();
        },
        dragleave() {
            event.preventDefault();
        },
        handleDeleteAttachment: function(type) {
            event.preventDefault();
            this.deleteAttachment(type)
        },
        deleteAttachment(type){
            var input = null
            switch (type) {
                case 'signature':
                    this.signature_file = {}
                    this.signature_src = null
                    input = this.$refs.signature_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'permanent_appointment':
                    this.permanent_appointment_file = {}
                    this.permanent_appointment_src = null
                    input = this.$refs.permanent_appointment_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'prc_id':
                    this.prc_id_file = {}
                    this.prc_id_src = null
                    input = this.$refs.prc_id_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'school_id':
                    this.school_id_file = {}
                    this.school_id_src = null
                    input = this.$refs.school_id_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'payslip':
                    this.payslip_file = {}
                    this.payslip_src = null
                    input = this.$refs.payslip_file
                    input.type = 'text'
                    input.type = 'file'

                    // delete additional payslip 1
                    this.showAdditionalPayslip1 = false
                    this.additional_payslip_1_file = {}
                    this.additional_payslip_1_src = null
                    input = this.$refs.additional_payslip_1_file
                    input.type = 'text'
                    input.type = 'file'

                    // delete additional payslip 2
                    this.showAdditionalPayslip2 = false
                    this.additional_payslip_2_file = {}
                    this.additional_payslip_2_src = null
                    input = this.$refs.additional_payslip_2_file
                    input.type = 'text'
                    input.type = 'file'

                    // delete additional payslip 3
                    this.showAdditionalPayslip3 = false
                    this.additional_payslip_3_file = {}
                    this.additional_payslip_3_src = null
                    input = this.$refs.additional_payslip_3_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'selfie':
                    this.selfie_file = {}
                    this.selfie_src = null
                    input = this.$refs.selfie_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'additional_payslip_1':
                    this.additional_payslip_1_file = {}
                    this.additional_payslip_1_src = null
                    input = this.$refs.additional_payslip_1_file
                    input.type = 'text'
                    input.type = 'file'

                    // delete additional payslip 2
                    this.showAdditionalPayslip2 = false
                    this.additional_payslip_2_file = {}
                    this.additional_payslip_2_src = null
                    input = this.$refs.additional_payslip_2_file
                    input.type = 'text'
                    input.type = 'file'

                    // delete additional payslip 3
                    this.showAdditionalPayslip3 = false
                    this.additional_payslip_3_file = {}
                    this.additional_payslip_3_src = null
                    input = this.$refs.additional_payslip_3_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'additional_payslip_2':
                    this.additional_payslip_2_file = {}
                    this.additional_payslip_2_src = null
                    input = this.$refs.additional_payslip_2_file
                    input.type = 'text'
                    input.type = 'file'

                    // delete additional payslip 3
                    this.showAdditionalPayslip3 = false
                    this.additional_payslip_3_file = {}
                    this.additional_payslip_3_src = null
                    input = this.$refs.additional_payslip_3_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'additional_payslip_3':
                    this.additional_payslip_3_file = {}
                    this.additional_payslip_3_src = null
                    input = this.$refs.additional_payslip_3_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                default:
                    break;
            }
        },
        validateAttachment(){
            var valid = true
            if( this.submit_signature == true && !(this.signature_file instanceof File) ){
                this.error_signature = true
                this.error_signature_color = true
                setTimeout(() => {
                    this.error_signature = false
                }, 500)

                valid = false
            } 

            if( this.submit_permanent_appointment == true && !(this.permanent_appointment_file instanceof File) ){
                this.error_permanent_appointment = true
                this.error_permanent_appointment_color = true
                setTimeout(() => {
                    this.error_permanent_appointment = false
                }, 500)

                valid = false
            }

            if( this.submit_prc_id == true && !(this.prc_id_file instanceof File) ){
                this.error_prc_id = true
                this.error_prc_id_color = true
                setTimeout(() => {
                    this.error_prc_id = false
                }, 500)

                valid = false
            }

            if( this.submit_school_id == true && !(this.school_id_file instanceof File) ){
                this.error_school_id = true
                this.error_school_id_color = true
                setTimeout(() => {
                    this.error_school_id = false
                }, 500)

                valid = false
            }

            if( this.submit_payslip == true && !(this.payslip_file instanceof File) ){
                this.error_payslip = true
                this.error_payslip_color = true
                setTimeout(() => {
                    this.error_payslip = false
                }, 500)

                valid = false
            }
            
            if( this.submit_selfie == true && !(this.selfie_file instanceof File) ){
                this.error_selfie = true
                this.error_selfie_color = true
                setTimeout(() => {
                    this.error_selfie = false
                }, 500)

                valid = false
            }

            if( this.submit_additional_payslip_1 == true && !(this.additional_payslip_1_file instanceof File) ){
                this.error_additional_payslip_1 = true
                this.error_additional_payslip_1_color = true
                setTimeout(() => {
                    this.error_additional_payslip_1 = false
                }, 500)

                valid = false
            }

            if( this.submit_additional_payslip_2 == true && !(this.additional_payslip_2_file instanceof File) ){
                this.error_additional_payslip_2 = true
                this.error_additional_payslip_2_color = true
                setTimeout(() => {
                    this.error_additional_payslip_2 = false
                }, 500)

                valid = false
            }
            
            if( this.submit_additional_payslip_3 == true && !(this.additional_payslip_3_file instanceof File) ){
                this.error_additional_payslip_3 = true
                this.error_additional_payslip_3_color = true
                setTimeout(() => {
                    this.error_additional_payslip_3 = false
                }, 500)

                valid = false
            }

            return valid
        },
        tosubmit(){
            this.$emit('submit')
        }
    },
}
</script>

<style scoped>
    img{
        max-width: 100%
    }
</style>